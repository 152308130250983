'use client'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {en} from "@/app/i18n/en";
import {he} from "@/app/i18n/he";

export const LS_LNG_KEY = 'i18nextLng'

export const LANGUAGE = {
  HE: 'he',
  EN: 'en'
}
export const fallbackLng = LANGUAGE.HE;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      [LANGUAGE.EN]: {
        translation: en
      },
      [LANGUAGE.HE]: {
        translation: he
      }
    },
    lng: fallbackLng,
    fallbackLng: fallbackLng,

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });