export const he =  {
	termsAndConditions: 'לתנאי השימוש ומדיניות פרטיות',
	termsAndConditionsTitle: 'תנאי שימוש ומדיניות פרטיות',
	termsAndConditionsContent: 'מראת גוני משתמשת במצלמה, שכן המצלמה מעניקה לנו את היכולת לתת לכם את השירות . המידע האישי שלך שאספנו עליך בשימוש במראה נמחק לאחר השימוש שלך. אנחנו לא שומרים תמונת פנים או שום מידע אישי כגון שם מלא או פרטי תשלום כתובת וכדומה . ארגון וניתוח של מידע עבור הקהילה המתרחבת שלנו הם מרכיב מרכזי בשירות שלנו. חלק גדול מהשירות הוא יצירה ושימוש בטכנולוגיות מתקדמות שעוזרות לנו להתאים אישית את השירות שלנו, להגן עליו ולשפר אותו בהיקפים גדולים במיוחד למען קהילה עולמית רחבה. טכנולוגיות כמו בינה מלאכותית ולמידת מכונה מעניקות לנו את הכוח ליישם תהליכים מורכבים בשירות שלנו על כל חלקיו. טכנולוגיות אוטומטיות עוזרות לנו גם להבטיח את הפונקציונליות והתקינות של השירות שלנו. שים לב שהמידע האישי שלך לא מופץ או משותף עם שום גורם או מכונה שתאפשר זיהוי שלך בתור משתמש בשום שלב . המידע שאנו אוספים ומעבדים משמש אותנו לשיפור יכולות ההלבשה של החברה, כאשר המידע מעובד ללא תמונת פנים וללא יכולת זיהוי .לדוגמה, אם מדדת בגד מול המראה אנחנו מעבדים את התמונה של לפני ואחרי המדידה על מנת לשפר את האלגוריתם שלנו  ולא יודעים למי שייכת התמונה או יכולים לשייך אותה אליך בשום שלב.',
	clickToMeasure: 'לחצו למדידה',
	photoCountdown:
		'התמונה שלך תילקח בעוד {{countdown}} שניות',
	chooseYourPicture: 'בחרו תמונה אחת',
	continueToProductsList: 'אפשר להמשיך לבחירת בגדים',
	retakePicture: 'לצילום מחדש',
	startTryOn: 'למדידה וירטואלית',
	productItemType: 'סוג פריט: {{item_type}}',
	productItemColor: 'צבע: {{color}}',
	seeInWardrobe: 'לראות בארון בגדים',
	description: 'תיאור',
	gender: 'מגדר',
	category: 'קטגוריה',
	color: "צבע",
	reshoot: 'צילום מחדש',
	likedList: 'אהובים',
	wardrobe: 'ארון בגדים',
	products: 'מוצרים',
	progress: 'התקדמות',
	noProcessedResults: 'ארון בגדים',
	resultsAboutToBeReady: 'בקרוב תדעו איך זה נראה עליכם',
	looksGood: 'נראה טוב',
	noProductsInWishlist: 'לא נבחרו מוצרים אהובים',
	reshotCacheClearingWarning: 'צילום מחדש ימחק את כל התמונות שלכם מארון הבגדים, האם אתם בטוחים?',
	women: 'נשים',
	men: 'גברים',
	other: 'אחר',
}